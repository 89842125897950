import React from "react"
import * as classNames from "classnames"
import { Ul, Li } from "components/typography/typographyFormatting"
import { SecondaryButton } from "components/buttons/buttons"
import styles from "./pointer.module.css"

const Ball = ({
  style,
  styleClass,
  onPointerDown,
  onPointerMove,
  onPointerUp,
  onPointerCancel,
}) => {
  return (
    <div
      style={style}
      className={classNames(styles.circle, styleClass)}
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
      onPointerCancel={onPointerCancel}
    />
  )
}

export class PointerSize extends React.Component {
  state = {
    data: [
      { x: 10, y: 20 },
      { x: 80, y: 80 },
      { x: 100, y: 120 },
      { x: 200, y: 300 },
      { x: 50, y: 100 },
    ],
  }
  isDragging = false
  initialX = 0
  initialY = 0
  elementWidth = 0
  elementHeight = 0
  containerWidth = 0
  containerHeight = 0

  componentDidMount() {
    this.setContainerSize()
  }

  pointerDown = e => {
    this.isDragging = true
    e.target.setPointerCapture(e.pointerId)

    this.setContainerSize()

    // Store initial circle position
    this.positionDelta(e)

    // Store element size
    this.elementWidth = e.target.offsetWidth
    this.elementHeight = e.target.offsetHeight
  }

  pointerMove = (i, e) => {
    if (!this.isDragging) {
      return
    }
    const { deltaX, deltaY } = this.positionDelta(e)

    let newX = this.state.data[i].x + deltaX
    let newY = this.state.data[i].y + deltaY

    // Keep within container width
    if (newX < 0) {
      newX = 0
    } else if (newX > this.containerWidth - this.elementWidth) {
      newX = this.containerWidth - this.elementWidth
    }

    // Keep within container height
    if (newY < 0) {
      newY = 0
    } else if (newY > this.containerHeight - this.elementHeight) {
      newY = this.containerHeight - this.elementHeight
    }

    const newState = [
      ...this.state.data.slice(0, i),
      { x: newX, y: newY },
      ...this.state.data.slice(i + 1),
    ]

    this.setState({ data: newState })
  }

  pointerUp = () => (this.isDragging = false)

  positionDelta = e => {
    const pointerX = e.pageX
    const pointerY = e.pageY
    const delta = {
      deltaX: pointerX - this.initialX,
      deltaY: pointerY - this.initialY,
    }
    this.initialX = pointerX
    this.initialY = pointerY
    return delta
  }

  setContainerSize = () => {
    this.containerWidth = this.containerRef.clientWidth
    this.containerHeight = this.containerRef.clientHeight
  }

  render() {
    return (
      <div className={styles.container}>
        <div
          ref={containerRef => {
            this.containerRef = containerRef
          }}
          className={styles.draggableContainer}
        >
          {this.state.data.map((item, i) => (
            <Ball
              key={i}
              style={{
                left: `${item.x}px`,
                top: `${item.y}px`,
                width: `${item.size}px`,
                height: `${item.size}px`,
              }}
              styleClass={`circle${i}`}
              onPointerDown={this.pointerDown}
              onPointerMove={e => this.pointerMove(i, e)}
              onPointerUp={this.pointerUp}
              onPointerCancel={this.pointerUp}
            />
          ))}
        </div>
        <Ul className={styles.pointerList}>
          <Li>64px</Li>
          <Li>48px (Material)</Li>
          <Li>44px (HIG)</Li>
          <Li>24px</Li>
          <Li>8px</Li>
        </Ul>
      </div>
    )
  }
}

export class PointerEvents extends React.Component {
  state = {
    pointerType: null,
    height: null,
    width: null,
    pressure: null,
    tiltX: null,
    tiltY: null,
    button: null,
  }

  pointerDown = e => {
    this.setState({
      pointerType: e.pointerType,
      height: e.height.toFixed(0),
      width: e.width.toFixed(0),
      pressure: e.pressure * 100,
      tiltX: e.tiltX,
      tiltY: e.tiltY,
      button: e.button,
    })
  }

  render() {
    const {
      pointerType,
      height,
      width,
      pressure,
      tiltX,
      tiltY,
      button,
    } = this.state

    return (
      <>
        <SecondaryButton
          onPointerDown={this.pointerDown}
          className={styles.eventsButton}
        >
          Detect pointer
        </SecondaryButton>

        {pointerType && (
          <Ul className={styles.list}>
            {pointerType === "mouse" && (
              <Li className={styles.pointerMouse}>
                You {button === 2 ? "right" : "left"} clicked with a mouse
              </Li>
            )}
            {pointerType === "touch" && (
              <Li className={styles.pointerTouch}>You tapped a touch device</Li>
            )}
            {pointerType === "pen" && (
              <Li className={styles.pointerTouch}>
                You tapped with a stylus or pen
              </Li>
            )}

            <Li className={styles.pointerSize}>
              The pointer size is {width}px by {height}px
            </Li>

            <Li className={styles.pointerPressure}>
              The pressure was {pressure}% of what the device supports
              {pointerType === "mouse" && ". Mouse always reports 50%."}
            </Li>

            {tiltX === 0 && (
              <Li className={styles.pointerAngle}>
                Your pointer doesn't register tilt. Try a stylus or pen.
              </Li>
            )}
            {tiltX > 0 && (
              <Li className={styles.pointerAngle}>
                Your tilt angle was measured at {tiltX}º on the X axis and{" "}
                {tiltY}º on the Y axis
              </Li>
            )}
          </Ul>
        )}
      </>
    )
  }
}

export const PointerSpectrum = () => {
  return (
    <Ul className={styles.pointerSpectrumContainer}>
      <Li className={styles.wacom}>Wacom</Li>
      <Li className={styles.mouse}>Mouse</Li>
      <Li className={styles.trackpad}>Trackpad</Li>
      <Li className={styles.smartphone}>Smartphone</Li>
      <Li className={styles.plane}>Plane screens</Li>
    </Ul>
  )
}
