import * as React from "react"
import * as classNames from "classnames"
import styles from "./buttons.module.css"

/* Button */

const Button = ({
  onPointerDown,
  children,
  className,
  type,
  disabled = false,
  style,
}) => {
  const allClassNames = classNames(styles.button, style, className)

  return (
    <button
      className={allClassNames}
      onPointerDown={onPointerDown}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export const SecondaryButton = props => (
  <Button {...props} style={styles.secondary} />
)

/* Link button */

const LinkButton = ({ href, children, className, style }) => {
  const allClassNames = classNames(styles.button, style, className)

  return (
    <a href={href} className={allClassNames}>
      {children}
    </a>
  )
}

export const SecondaryLinkButton = props => (
  <LinkButton {...props} style={styles.secondary} />
)
