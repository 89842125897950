import React from "react"
import { Ul, Li } from "components/typography/typographyFormatting"
import { SecondaryButton } from "components/buttons/buttons"
import styles from "./sensors.module.css"

export class Geolocation extends React.Component {
  state = {
    supported: null,
    fetching: null,
    accuracy: null,
    altitude: null,
    heading: null,
    latitude: null,
    longitude: null,
    speed: null,
  }

  options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  }

  success = position => {
    this.setState({
      fetching: false,
      supported: false,
      accuracy: position.coords.accuracy,
      altitude: position.coords.altitude,
      heading: position.coords.heading,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      speed: position.coords.speed,
    })
  }

  error = () => {
    this.setState({ supported: false, fetching: false })

    alert("Location not available")
  }

  pointerDown = () => {
    if (!navigator.geolocation) {
      this.setState({ supported: false })
    } else {
      this.setState({ supported: true, fetching: true })
      navigator.geolocation.getCurrentPosition(
        this.success,
        this.error,
        this.options
      )
    }
  }

  render() {
    const {
      latitude,
      longitude,
      accuracy,
      altitude,
      heading,
      speed,
      fetching,
    } = this.state

    return (
      <>
        <SecondaryButton
          onPointerDown={this.pointerDown}
          className={styles.geolocationButton}
        >
          {fetching ? "Fetching location…" : "Fetch location"}
        </SecondaryButton>
        {latitude && (
          <Ul className={styles.list}>
            <Li className={styles.location}>
              You are located at {latitude}º, {longitude}º
            </Li>
            <Li className={styles.locationAccuracy}>
              Your location is accurate to {accuracy}m
            </Li>
            <Li className={styles.locationAltitude}>
              {altitude
                ? `Your current altitude is ${altitude}m above sea level`
                : "This device doesn't support altitude"}
            </Li>
            <Li className={styles.locationSpeed}>
              {speed
                ? `Your current speed is ${speed}m/s`
                : "This device doesn't support speed"}
            </Li>
            <Li className={styles.locationHeading}>
              {heading
                ? `The direction you are travelling in is ${heading}º from true north`
                : "This device doesn't support heading"}
            </Li>
          </Ul>
        )}
      </>
    )
  }
}
