import React, { useState, useEffect } from "react"
import { Ul, Li } from "components/typography/typographyFormatting"
import styles from "./viewport.module.css"
import _ from "lodash"

export const Viewport = () => {
  const [units, setUnits] = useState(0)

  useEffect(() => {
    calculateUnits()

    window.addEventListener(
      "resize",
      _.debounce(function() {
        calculateUnits()
      }, 16)
    )
  }, [])

  function calculateUnits() {
    const coverageCalculation =
      ((window.innerWidth * window.innerHeight) /
        (window.screen.width * window.screen.height)) *
      100
    const coverageFormatted = coverageCalculation.toFixed(0)
    const gcdViewport = gcd(window.innerWidth, window.innerHeight)
    const gcdScreen = gcd(window.screen.width, window.screen.height)
    const aspectRatioScreen = `${window.screen.width / gcdScreen}:${window
      .screen.height / gcdScreen}`
    const aspectRatioViewport = `${window.innerWidth /
      gcdViewport}:${window.innerHeight / gcdViewport}`

    setUnits({
      viewportWidth: window.innerWidth,
      screenWidth: window.screen.width,
      viewportHeight: window.innerHeight,
      screenHeight: window.screen.height,
      viewportAspect: aspectRatioViewport,
      screenAspect: aspectRatioScreen,
      coverage: coverageFormatted,
    })
  }

  // Calculate Aspect ratio - https://stackoverflow.com/questions/1186414
  function gcd(a, b) {
    return b === 0 ? a : gcd(b, a % b)
  }

  return (
    <Ul className={styles.list}>
      <Li className={styles.viewportWidth}>
        <b>Width</b> - Your viewport is {units.viewportWidth}px wide and the
        screen is {units.screenWidth}px
      </Li>
      <Li className={styles.viewportHeight}>
        <b>Height</b> - Your viewport is {units.viewportHeight}px high and the
        screen is {units.screenHeight}px
      </Li>
      <Li className={styles.viewportAspect}>
        <b>Aspect ratio</b> - Your viewport aspect ratio {units.viewportAspect}{" "}
        is and the screen is {units.screenAspect}
      </Li>
      <Li className={styles.viewportCoverage}>
        <b>Coverage</b> - Your viewport covers {units.coverage}% of your screen
      </Li>
    </Ul>
  )
}

export const Breakpoints = () => {
  return (
    <div className={styles.networkComparisonContainer}>
      <div className={styles.networkComparison}>
        <div className={styles.title}>0px and up</div>
        <ul>
          <li>iPhone 5</li>
          <li>iPhone 11</li>
          <li>Pixel 2</li>
          <li>Note 9</li>
          <li>OnePlus 8</li>
        </ul>
      </div>
      <div className={styles.networkComparison}>
        <div className={styles.title}>600px and up</div>
        <ul>
          <li>iPhone 11 (Ls)</li>
          <li>iPhone 6 Plus (Ls)</li>
          <li>Pixel 2 (Ls)</li>
          <li>Note 9 (Ls)</li>
          <li>iPad Pro 10.5</li>
        </ul>
      </div>
      <div className={styles.networkComparison}>
        <div className={styles.title}>900px and up</div>
        <ul>
          <li>iPad (Ls)</li>
          <li>iPad Pro 10.5 (Ls)</li>
          <li>iPad Pro 12.9</li>
          <li>MacBook Air 11″</li>
          <li>Surface Go 2</li>
        </ul>
      </div>
      <div className={styles.networkComparison}>
        <div className={styles.title}>1200px and up</div>
        <ul>
          <li>MacBook Pro 13″</li>
          <li>MacBook Pro 15″</li>
          <li>Surface Studio</li>
          <li>Projectors</li>
          <li>TV’s</li>
        </ul>
      </div>
    </div>
  )
}
