import { InfoAlert } from "components/alert/alert";
import DeviceBanner from "../../../../src/posts/dont-design-for-mobile/banner/banner";
import { Viewport, Breakpoints } from "../../../../src/posts/dont-design-for-mobile/viewport/viewport";
import { PointerSize, PointerSpectrum, PointerEvents } from "../../../../src/posts/dont-design-for-mobile/pointer/pointer";
import { Geolocation } from "../../../../src/posts/dont-design-for-mobile/sensors/sensors";
import { NetworkStatus, NetworkComparison } from "../../../../src/posts/dont-design-for-mobile/network/network";
import * as React from 'react';
export default {
  InfoAlert,
  DeviceBanner,
  Viewport,
  Breakpoints,
  PointerSize,
  PointerSpectrum,
  PointerEvents,
  Geolocation,
  NetworkStatus,
  NetworkComparison,
  React
};