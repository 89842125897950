import React from "react"
import Banner from "components/banner/banner"
import styles from "./banner.module.css"

const DeviceBanner = ({ children }) => {
  return (
    <Banner className={styles.banner}>
      <div className={styles.device}>
        <div className={styles.deviceScreen1} />
        <div className={styles.deviceScreen2} />
        <div className={styles.deviceScreen3} />
      </div>
      {children}
    </Banner>
  )
}

export default DeviceBanner
