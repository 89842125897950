import React, { useState, useEffect } from "react"
import { SuccessAlert, ErrorAlert } from "components/alert/alert"
import styles from "./network.module.css"

export const NetworkStatus = () => {
  const [online, setOnline] = useState(true)

  useEffect(() => {
    window.addEventListener("offline", function() {
      setOnline(false)
    })

    window.addEventListener("online", function() {
      setOnline(true)
    })
  })

  if (online) {
    return (
      <SuccessAlert>
        <strong>You are currently online!</strong> Try turning off your
        connection temporarily…
      </SuccessAlert>
    )
  } else {
    return (
      <ErrorAlert>
        <strong>You are currently offline!</strong> You've had your fun, lets
        turn that connection back on…
      </ErrorAlert>
    )
  }
}

export const NetworkComparison = () => {
  return (
    <div className={styles.networkComparisonContainer}>
      <div className={styles.networkComparison}>
        <div className={styles.title}>WIFI faster</div>
        <ul>
          <li>China</li>
          <li>Russia</li>
          <li>Singapore</li>
          <li>South Korea</li>
          <li>UK</li>
          <li>USA</li>
        </ul>
      </div>
      <div className={styles.networkComparison}>
        <div className={styles.title}>Similar speeds</div>
        <ul>
          <li>Belgium</li>
          <li>Brazil</li>
          <li>Finland</li>
          <li>Hungary</li>
          <li>Norway</li>
          <li>New Zealand</li>
        </ul>
      </div>
      <div className={styles.networkComparison}>
        <div className={styles.title}>Mobile faster</div>
        <ul>
          <li>Australia</li>
          <li>Egypt</li>
          <li>Greece</li>
          <li>South Africa</li>
          <li>Turkey</li>
          <li>Qatar</li>
        </ul>
      </div>
    </div>
  )
}
