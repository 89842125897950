import * as React from "react"
import styles from "./typographyFormatting.module.css"
import * as classNames from "classnames"
import { Link } from "gatsby"

export const A = ({ children, href, className }) => {
  // https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
  const internalLinkRegex = new RegExp("^(?:[a-z]+:)?//", "i")
  const isExternalLink = internalLinkRegex.test(href)

  if (!isExternalLink) {
    return (
      <Link to={href} className={styles.a}>
        {children}
      </Link>
    )
  } else {
    return (
      <a href={href} className={styles.a}>
        {children}
      </a>
    )
  }
}

export const Ul = ({ children, className }) => {
  const allClassNames = classNames(styles.ul, className)
  return <ul className={allClassNames}>{children}</ul>
}

export const Ol = ({ children, className }) => {
  const allClassNames = classNames(styles.ol, className)
  return <ol className={allClassNames}>{children}</ol>
}

export const Li = ({ children, className }) => {
  const allClassNames = classNames(styles.li, className)
  return <li className={allClassNames}>{children}</li>
}

// export const Hr = ({ className }: Props) => {
//   const allClassNames = classNames(styles.hr, className)
//   return <hr className={allClassNames} />
// }

// export const Blockquote = ({ children }: Props) => {
//   return <blockquote className={styles.blockquote}>{children}</blockquote>
// }

// export const Code = ({ children }: Props) => {
//   return <code className={styles.code}>{children}</code>
// }

// export const Pre = ({ children }: Props) => {
//   return <pre className={styles.pre}>{children}</pre>
// }
