import React from "react"
import * as classNames from "classnames"
import styles from "./banner.module.css"

const Banner = ({ className, children }) => {
  const allClassNames = classNames(styles.banner, className)
  return <div className={allClassNames}>{children}</div>
}

export default Banner
