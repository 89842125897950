import React from "react"
import * as classNames from "classnames"
import styles from "./alert.module.css"

const Alert = ({ className, children, style }) => {
  const allClassNames = classNames(styles.alert, style, className)
  return <div className={allClassNames}>{children}</div>
}

export const InfoAlert = props => <Alert {...props} style={styles.info} />
export const SuccessAlert = props => <Alert {...props} style={styles.success} />
export const ErrorAlert = props => <Alert {...props} style={styles.error} />
