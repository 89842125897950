import japanStyles from "../../../../src/posts/japan-2019/styles.module.css";
import Banner from "components/banner/banner";
import { Photoset, PhotosetRow } from "components/photoset/photoset";
import japan01 from "../../../../src/posts/japan-2019/japan01.jpg";
import japan02 from "../../../../src/posts/japan-2019/japan02.jpg";
import japan03 from "../../../../src/posts/japan-2019/japan03.jpg";
import japan04 from "../../../../src/posts/japan-2019/japan04.jpg";
import japan05 from "../../../../src/posts/japan-2019/japan05.jpg";
import japan06 from "../../../../src/posts/japan-2019/japan06.jpg";
import japan07 from "../../../../src/posts/japan-2019/japan07.jpg";
import japan08 from "../../../../src/posts/japan-2019/japan08.jpg";
import japan09 from "../../../../src/posts/japan-2019/japan09.jpg";
import japan10 from "../../../../src/posts/japan-2019/japan10.jpg";
import japan11 from "../../../../src/posts/japan-2019/japan11.jpg";
import japan12 from "../../../../src/posts/japan-2019/japan12.jpg";
import japan13 from "../../../../src/posts/japan-2019/japan13.jpg";
import japan14 from "../../../../src/posts/japan-2019/japan14.jpg";
import * as React from 'react';
export default {
  japanStyles,
  Banner,
  Photoset,
  PhotosetRow,
  japan01,
  japan02,
  japan03,
  japan04,
  japan05,
  japan06,
  japan07,
  japan08,
  japan09,
  japan10,
  japan11,
  japan12,
  japan13,
  japan14,
  React
};