import React from "react"
import styles from "./photoset.module.css"
import * as classNames from "classnames"

export const Photoset = ({ className, children }) => {
  const photosetClasses = classNames(styles.photoset, className)
  return <div className={photosetClasses}>{children}</div>
}

export const PhotosetRow = ({ className, children }) => {
  const photosetRowClasses = classNames(styles.photosetRow, className)
  return <div className={photosetRowClasses}>{children}</div>
}
